const postCssPlugins = require("./postcss-config");

let activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "dev";

require("dotenv").config({
  path: `./env/.env.${activeEnv}`,
});

module.exports = {
  siteMetadata: {
    title: `stromee`,
    description: `stromee bietet Ökostrom zum Einkaufspreis. Tarifberechnung, 
    Anbieterwechsel, Abrechnung. Einfach, schnell und digital!`,
    siteUrl: `https://stromee.de`,
    image: "/thumbnail.png",
  },
  plugins: [
    {
      resolve: `gatsby-plugin-netlify-cache`,
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        exclude: [
          "/app",
          "/blog",
          "/check-email",
          "/blog-article",
          "/faq-category",
          "/iframe-calculator-landingpage",
          "/change-email",
          "/password-recovery",
          "/email-verification",
          "/password-recovery/expired",
          "/password-recovery/success",
          "/blog/selbstgemachte-bienenwachstücher",
          "/jobs",
          "/job-post",
        ],
      },
    },
    `gatsby-plugin-material-ui`,
    `gatsby-plugin-typescript`,
    {
      resolve: `gatsby-plugin-svgr`,
      options: {
        prettier: true,
        svgo: true,
        svgoConfig: {
          removeViewBox: true,
          cleanupIDs: true,
        },
      },
    },
    {
      resolve: `gatsby-plugin-sass`,
      options: {
        postCssPlugins: [...postCssPlugins],
        // Override the file regex for SASS
        sassRuleTest: /\.s(a|c)ss$/,
        // Override the file regex for CSS modules
        sassRuleModulesTest: /\.mod\.s(a|c)ss$/,
      },
    },
    `gatsby-plugin-styled-components`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/assets`,
        name: `assets`,
      },
    },
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-images`,
            options: {
              maxWidth: 590,
            },
          },
          {
            resolve: `gatsby-remark-responsive-iframe`,
            options: {
              wrapperStyle: `margin-bottom: 1.0725rem`,
            },
          },
          `gatsby-remark-prismjs`,
          `gatsby-remark-copy-linked-files`,
          `gatsby-remark-smartypants`,
        ],
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: `UA-137989242-4`,
        head: true,
        anonymize: true,
      },
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: `GTM-WL8QP45`,
        includeInDevelopment: true,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `stromee`,
        /* eslint-disable */
        short_name: `stromee`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#4646eb`,
        /* eslint-enable */
        display: `minimal-ui`,
        icon: `src/assets/favicon-stromee.png`,
      },
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [`Montserrat:400,600,700,900`],
        display: "swap",
      },
    },
    {
      resolve: `gatsby-plugin-sass-resources`,
      options: {
        resources: [`${__dirname}/src/styles/resources.scss`],
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
};
